<template>
    <b-row align-v="center" class="w-100 d-flex justify-content-start">
      <b-col cols="auto" class="mr-auto p-3">
        <div class="d-flex align-items-center">
            <span class="hover-pointer d-flex align-items-center" @click="sortDirection">
              <b-icon-sort-down v-if="sortDescending" class="my-auto" scale="1.2" />
              <b-icon-sort-up-alt v-else class="my-auto" scale="1.2" />
              <span class="ml-2">Company Sort</span>
            </span>
        </div>
      </b-col>

      <b-col cols="auto">
        <div class="flex-fill mb-2">
          <b-button
            id="search-filters-btn"
            v-b-toggle.collapse-filters
            variant="secondary"
            class="filter-btn"
            aria-label="search filters button"
            @click="toggleArrowUpDisplay"
          >
            Filter
            <div id="arrow-up" class="hidden" />
          </b-button>
        </div>
      </b-col>
      <b-col cols="auto">
        <div class="search-container d-flex align-items-center">
          <b-input-group>
            <b-form-input
              v-model="searchFilter"
              type="text"
              placeholder="Search"
              @change="searchBarChanged"
            />
            <b-input-group-text>
              <feather-icon type="search" />
            </b-input-group-text>
          </b-input-group>
        </div>
      </b-col>
      <b-collapse id="collapse-filters" class="w-100">
        <div class="w-100 d-flex flex-wrap align-items-center justify-content-center filters-container">
          <b-row class="w-100 d-flex justify-content-center mb-2">
            <b-col class="filter-column" xl="2" lg="3" md="3" cols="6">
              <company-select
                class="filter-option"
                :placeholder="'Company'"
                :reset-company="resetCompany"
                @input="companySelectHandler"
                @resetCompany="resetCompanyHandler"
              />
            </b-col>
            <b-col class="filter-column" xl="2" lg="3" md="3" cols="6">
              <jurisdiction-select
                class="filter-option"
                :is-disable="false"
                :placeholder="'Jurisdiction'"
                :reset-jurisdiction="resetJurisdiction"
                @input="jurisdictionSelectHandler"
                @resetJurisdiction="resetJurisdictionHandler"
              />
            </b-col>
            <b-col class="filter-column" xl="2" lg="3" md="3" cols="6">
              <multi-select
                v-model="serviceType"
                class="filter-option"
                label="name"
                track-by="value"
                :options="serviceTypeOptions"
                placeholder="Service Type"
                deselect-label=""
                select-label=""
                @input="serviceTypeSelectHandler"
              />
            </b-col>
            <b-col class="filter-column" xl="2" lg="3" md="3" cols="6">
              <multi-select
                v-model="statusType"
                class="filter-option"
                label="name"
                track-by="value"
                :options="statusTypeOptions"
                placeholder="Status Type"
                deselect-label=""
                select-label=""
                @input="statusTypeSelectHandler"
              />
            </b-col>
            <b-col class="filter-column" xl="2" lg="3" md="3" cols="6">
              <b-form-datepicker
                v-model="startDate"
                placeholder="Start Date"
                class="filter-btn"
                size="sm"
                locale="en"
                :value-as-date="true"
                :dark="false"
                :no-flip="true"
                @input="startDateSelectHandler"
              />
            </b-col>
            <b-col class="filter-column" xl="2" lg="3" md="3" cols="6">
              <b-form-datepicker
                v-model="endDate"
                placeholder="End Date"
                class="filter-btn"
                size="sm"
                locale="en"
                :value-as-date="true"
                :dark="false"
                :no-flip="true"
                @input="stopDateSelectHandler"
              />
            </b-col>
          </b-row>
          <b-row class="w-100 d-flex justify-content-end align-items-center">
            <b-col class="filter-column d-flex justify-content-end" xl="6" lg="6" md="6" cols="12">
              <b-form-checkbox v-model="localShowCancelled" :disabled="this.query.status_type">
                <span class="pr-1 font-size-sm font-weight-normal">Show cancelled services</span>
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row class="w-100 d-flex justify-content-end align-items-center">
            <b-col class="filter-column d-flex justify-content-center" xl="2" lg="3" md="3" cols="12">
              <b-button
                id="apply-filters-btn"
                class="filter-btn"
                size="sm"
                variant="primary"
                aria-label="apply filters button"
                @click="submit"
              >
                Apply
              </b-button>
            </b-col>
            <b-col class="filter-column d-flex justify-content-center" xl="2" lg="3" md="3" cols="12">
              <b-button
                id="reset-filters-btn"
                class="filter-btn"
                size="sm"
                variant="secondary"
                aria-label="reset filters button"
                @click="resetFilters()"
              >
                Reset
              </b-button>
            </b-col>
          </b-row>
        </div>
      </b-collapse>
    </b-row>
</template>

<script>
  import _ from 'lodash'
  import CompanySelect from '@/components/CompanySelect'
  import JurisdictionSelect from '@/components/JurisdictionSelect'
  import MultiSelect from 'vue-multiselect'
  import FeatherIcon from '@/components/shared/FeatherIcon.vue'

  export default {
    name: 'ServiceSearchFiltersForm',
    components: { FeatherIcon, CompanySelect, JurisdictionSelect, MultiSelect },
    props: {
      fields: {
        type: Array,
        default: () => [],
      },
      filterOptions: {
        type: Object,
        default: () => {},
      },
      onSubmit: {
        type: Function,
        default: () => { return true },
      },
      searchScope: {
        type: String,
        default: '',
      },
      searchChanged: {
        type: Function,
        default: () => { return true },
      },
      showCancelled: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        query: {},
        searchName: this.searchScope,
        searchFilter: null,
        serviceType: '',
        statusType: '',
        resetJurisdiction: false,
        resetCompany: false,
        localShowCancelled: this.showCancelled,
        sortDescending: true,
        startDate: '',
        endDate: '',
      }
    },
    computed: {
      serviceTypeOptions() {
        return [
          {
            name: 'Blanket Agent Service',
            value: 'blanket-agent',
          },
          {
            name: 'Call Forwarding',
            value: 'call-forwarding',
          },
          {
            name: 'Compliance',
            value: 'compliance',
          },
          {
            name: 'Law On Call',
            value: 'law-on-call',
          },
          {
            name: 'Local DC Agent',
            value: 'local-dc-agent',
          },
          {
            name: 'Mail Forwarding',
            value: 'mail-forwarding',
          },
          {
            name: 'Registered Agent',
            value: 'registered-agent',
          },
        ]
      },
      statusTypeOptions() {
        return [
          {
            name: 'Active',
            value: 'active',
          },
          {
            name: 'Awaiting Client Input',
            value: 'awaiting-client-input',
          },
          {
            name: 'Cancelled',
            value: 'cancelled',
          },
          {
            name: 'Cancel Requested',
            value: 'cancel-requested',
          },
          {
            name: 'New',
            value: 'new',
          },
          {
            name: 'Trial Active',
            value: 'trial-active',
          },
          {
            name: 'Waiting On Information',
            value: 'waiting on information',
          },
          {
            name: 'Waiting On Payment',
            value: 'waiting on payment',
          },
        ]
      },
    },
    watch: {
      localShowCancelled(newValue) {
        this.$emit('update:showCancelled', newValue)
      },
    },
    methods: {
      companySelectHandler(selection) {
        this.query.company_id = selection
      },
      jurisdictionSelectHandler(selection) {
        this.query.jurisdiction_id = selection
      },
      resetFilters() {
        this.query = {}
        this.serviceType = ''
        this.statusType = ''
        this.localShowCancelled = false
        this.startDate = ''
        this.endDate = ''
        this.searchFilter = ''
        this.resetJurisdictionHandler(true)
        this.resetCompanyHandler(true)
      },
      resetJurisdictionHandler(selection = false) {
        this.resetJurisdiction = selection
        delete this.query.jurisdiction_id
      },
      resetCompanyHandler(selection = false) {
        this.resetCompany = selection
      },
      serviceTypeSelectHandler(selection) {
        selection ? this.query.service_type = selection.value : delete this.query.service_type
      },
      searchBarChanged(queryObject) {
        this.searchFilter = queryObject
        this.submit()
      },
      statusTypeSelectHandler(selection) {
        if (selection) {
          this.query.status_type = selection.value
          this.localShowCancelled = false
        } else {
          delete this.query.status_type
        }
      },
      startDateSelectHandler(selection) {
        this.query.start = selection.getTime() / 1000
      },
      stopDateSelectHandler(selection) {
        this.query.stop = selection.getTime() / 1000
      },
      sortDirection() {
        this.sortDescending = !this.sortDescending
        this.$emit('update-sort-direction')
      },
      submit() {
        const searchBar = {}

        if (this.searchFilter) {
          searchBar.search_name = this.searchName
          searchBar.query = this.searchFilter
        } else {
          delete searchBar.search_name
          delete searchBar.query
        }

        this.$emit('refresh-companies', this.query, searchBar)
      },
      toggleArrowUpDisplay() {
        const arrow = document.getElementById('arrow-up')
        if (arrow.classList.contains('hidden')) {
          arrow.classList.remove('hidden')
        } else {
          arrow.classList.add('hidden')
        }
      },
    },
  }
</script>

<style lang="scss" scoped >

  #reset-filters-btn { width: 100% !important; }

  #arrow-up {
    position: absolute;
    top: 100%;
    transform: translateX(100%);
    padding-bottom: 10px;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid #F1F1F1;
  }

  .search-container {
    min-width: 250px;
    .form-control {
      min-height: 40px !important;
      flex: 1;
      border-right: none;
    }

    .input-group-text {
      height: 40px !important;
      border-left: none;
      border-radius: 0 4px 4px 0;
    }
  }

  .title {
    margin-bottom: 40px;
  }

  .hover-pointer {
    :hover {
      cursor: pointer;
    }
  }

  .filters-container {
    padding: 1.5625em 0.375em 0.625em 0.375em;
    margin: 1.125em 0 0 0.9375em;
    border-radius: 8px;
    background: #F1F1F1;
  }

  .filter-column {
    padding: 0 0.375em;
  }

  .hidden { display: none; }

  .filter-column .font-size-sm {
    font-size: $font-size-sm;
  }

  .filter-column .filter-btn {
    margin: 0.3125em 0 0 0;
  }

  .filter-btn {
    font-size: 0.750em;
    width: 175px;
    min-width: 150px;
  }

  .filter-option {
    width: 100%;
    height: 48px !important;
    margin-top: 5px !important;
  }

  .collapsed > .when-open,
  .not-collapsed > .when-closed {
    display: none;
  }

  .collapsed > .when-open,
  .not-collapsed > .when-closed {
    display: none;
  }

  @media only screen and (max-width: 991px) {
    .filters-container {
      margin-top: 28px; // height adjustment to connect with triangle
    }
  }

</style>

<style lang="scss" >
  .text-muted {
    color: #adadad !important;
    font-size: 14px !important;
  }
  // datepicker calendar icon container
  .b-form-btn-label-control.form-control > .btn {
    padding: 0.5em 0.625em !important;
  }

  // datepicker btn label
  .b-form-btn-label-control.form-control > label {
    display: flex;
    align-items: center;
    padding: 0px !important;
    margin: 0px !important;
    height: auto !important;
    min-height: 40px !important;
    max-height: 40px !important;
  }

  // datepicker btn container
  .b-form-btn-label-control.form-control {
    border: 1px solid #E8E7E8 !important;
    padding: 0px !important;
    margin: 1.125em 0 0 0.9375em;
    width: 98%;
    height: auto !important;
    min-height: 43px !important;
    max-height: 43px !important;
  }

  // filter select menus
  #collapse-filters > div.filters-container > div:nth-child(1) > div.filter-column > div.multiselect > div.multiselect__tags {
    border: 1px solid #E8E7E8 !important;
  }

  //filter options wrapper
  #collapse-filters > div.filters-container > div:nth-child(1) > div.filter-column > div.multiselect > div.multiselect__content-wrapper {
    min-width: fit-content;
  }

  //company filter wrapper
  #collapse-filters > div.filters-container > div:nth-child(1) > div.filter-column > div.company-select > div.multiselect__content-wrapper {
    min-width: 28rem;
  }

</style>
