var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    {
      staticClass: "w-100 d-flex justify-content-start",
      attrs: { "align-v": "center" },
    },
    [
      _c("b-col", { staticClass: "mr-auto p-3", attrs: { cols: "auto" } }, [
        _c("div", { staticClass: "d-flex align-items-center" }, [
          _c(
            "span",
            {
              staticClass: "hover-pointer d-flex align-items-center",
              on: { click: _vm.sortDirection },
            },
            [
              _vm.sortDescending
                ? _c("b-icon-sort-down", {
                    staticClass: "my-auto",
                    attrs: { scale: "1.2" },
                  })
                : _c("b-icon-sort-up-alt", {
                    staticClass: "my-auto",
                    attrs: { scale: "1.2" },
                  }),
              _c("span", { staticClass: "ml-2" }, [_vm._v("Company Sort")]),
            ],
            1
          ),
        ]),
      ]),
      _c("b-col", { attrs: { cols: "auto" } }, [
        _c(
          "div",
          { staticClass: "flex-fill mb-2" },
          [
            _c(
              "b-button",
              {
                directives: [
                  {
                    name: "b-toggle",
                    rawName: "v-b-toggle.collapse-filters",
                    modifiers: { "collapse-filters": true },
                  },
                ],
                staticClass: "filter-btn",
                attrs: {
                  id: "search-filters-btn",
                  variant: "secondary",
                  "aria-label": "search filters button",
                },
                on: { click: _vm.toggleArrowUpDisplay },
              },
              [
                _vm._v("\n        Filter\n        "),
                _c("div", { staticClass: "hidden", attrs: { id: "arrow-up" } }),
              ]
            ),
          ],
          1
        ),
      ]),
      _c("b-col", { attrs: { cols: "auto" } }, [
        _c(
          "div",
          { staticClass: "search-container d-flex align-items-center" },
          [
            _c(
              "b-input-group",
              [
                _c("b-form-input", {
                  attrs: { type: "text", placeholder: "Search" },
                  on: { change: _vm.searchBarChanged },
                  model: {
                    value: _vm.searchFilter,
                    callback: function ($$v) {
                      _vm.searchFilter = $$v
                    },
                    expression: "searchFilter",
                  },
                }),
                _c(
                  "b-input-group-text",
                  [_c("feather-icon", { attrs: { type: "search" } })],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "b-collapse",
        { staticClass: "w-100", attrs: { id: "collapse-filters" } },
        [
          _c(
            "div",
            {
              staticClass:
                "w-100 d-flex flex-wrap align-items-center justify-content-center filters-container",
            },
            [
              _c(
                "b-row",
                { staticClass: "w-100 d-flex justify-content-center mb-2" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "filter-column",
                      attrs: { xl: "2", lg: "3", md: "3", cols: "6" },
                    },
                    [
                      _c("company-select", {
                        staticClass: "filter-option",
                        attrs: {
                          placeholder: "Company",
                          "reset-company": _vm.resetCompany,
                        },
                        on: {
                          input: _vm.companySelectHandler,
                          resetCompany: _vm.resetCompanyHandler,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "filter-column",
                      attrs: { xl: "2", lg: "3", md: "3", cols: "6" },
                    },
                    [
                      _c("jurisdiction-select", {
                        staticClass: "filter-option",
                        attrs: {
                          "is-disable": false,
                          placeholder: "Jurisdiction",
                          "reset-jurisdiction": _vm.resetJurisdiction,
                        },
                        on: {
                          input: _vm.jurisdictionSelectHandler,
                          resetJurisdiction: _vm.resetJurisdictionHandler,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "filter-column",
                      attrs: { xl: "2", lg: "3", md: "3", cols: "6" },
                    },
                    [
                      _c("multi-select", {
                        staticClass: "filter-option",
                        attrs: {
                          label: "name",
                          "track-by": "value",
                          options: _vm.serviceTypeOptions,
                          placeholder: "Service Type",
                          "deselect-label": "",
                          "select-label": "",
                        },
                        on: { input: _vm.serviceTypeSelectHandler },
                        model: {
                          value: _vm.serviceType,
                          callback: function ($$v) {
                            _vm.serviceType = $$v
                          },
                          expression: "serviceType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "filter-column",
                      attrs: { xl: "2", lg: "3", md: "3", cols: "6" },
                    },
                    [
                      _c("multi-select", {
                        staticClass: "filter-option",
                        attrs: {
                          label: "name",
                          "track-by": "value",
                          options: _vm.statusTypeOptions,
                          placeholder: "Status Type",
                          "deselect-label": "",
                          "select-label": "",
                        },
                        on: { input: _vm.statusTypeSelectHandler },
                        model: {
                          value: _vm.statusType,
                          callback: function ($$v) {
                            _vm.statusType = $$v
                          },
                          expression: "statusType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "filter-column",
                      attrs: { xl: "2", lg: "3", md: "3", cols: "6" },
                    },
                    [
                      _c("b-form-datepicker", {
                        staticClass: "filter-btn",
                        attrs: {
                          placeholder: "Start Date",
                          size: "sm",
                          locale: "en",
                          "value-as-date": true,
                          dark: false,
                          "no-flip": true,
                        },
                        on: { input: _vm.startDateSelectHandler },
                        model: {
                          value: _vm.startDate,
                          callback: function ($$v) {
                            _vm.startDate = $$v
                          },
                          expression: "startDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "filter-column",
                      attrs: { xl: "2", lg: "3", md: "3", cols: "6" },
                    },
                    [
                      _c("b-form-datepicker", {
                        staticClass: "filter-btn",
                        attrs: {
                          placeholder: "End Date",
                          size: "sm",
                          locale: "en",
                          "value-as-date": true,
                          dark: false,
                          "no-flip": true,
                        },
                        on: { input: _vm.stopDateSelectHandler },
                        model: {
                          value: _vm.endDate,
                          callback: function ($$v) {
                            _vm.endDate = $$v
                          },
                          expression: "endDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                {
                  staticClass:
                    "w-100 d-flex justify-content-end align-items-center",
                },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "filter-column d-flex justify-content-end",
                      attrs: { xl: "6", lg: "6", md: "6", cols: "12" },
                    },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          attrs: { disabled: this.query.status_type },
                          model: {
                            value: _vm.localShowCancelled,
                            callback: function ($$v) {
                              _vm.localShowCancelled = $$v
                            },
                            expression: "localShowCancelled",
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "pr-1 font-size-sm font-weight-normal",
                            },
                            [_vm._v("Show cancelled services")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                {
                  staticClass:
                    "w-100 d-flex justify-content-end align-items-center",
                },
                [
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "filter-column d-flex justify-content-center",
                      attrs: { xl: "2", lg: "3", md: "3", cols: "12" },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "filter-btn",
                          attrs: {
                            id: "apply-filters-btn",
                            size: "sm",
                            variant: "primary",
                            "aria-label": "apply filters button",
                          },
                          on: { click: _vm.submit },
                        },
                        [_vm._v("\n            Apply\n          ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "filter-column d-flex justify-content-center",
                      attrs: { xl: "2", lg: "3", md: "3", cols: "12" },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "filter-btn",
                          attrs: {
                            id: "reset-filters-btn",
                            size: "sm",
                            variant: "secondary",
                            "aria-label": "reset filters button",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.resetFilters()
                            },
                          },
                        },
                        [_vm._v("\n            Reset\n          ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }